import React from 'react';
import { Link } from 'gatsby';
import kebabCase from '../utils/kebabCase';

const Tag = ({ text }) => (
  <Link to={`/tags/${kebabCase(text)}`}>
    <span className="inline-block bg-green-50 mr-2 mb-2 rounded-sm px-2 text-lg font-light uppercase text-black bg-gradient-to-br from-hakkei-bg-gradient-8-from to-hakkei-bg-gradient-8-to">
      {text}
    </span>
  </Link>
);

export default Tag;
