import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ZoomImage = ({ src, alt, className }) => {
  return (
    <div className={`zoom ${className}`}>
      <img src={src} alt={alt} className="h-full w-full"/>
    </div>
  );
};

export default ZoomImage;
