import React from 'react';
import { Link } from 'gatsby';
import formatDate from '../../utils/formatDate';
import Tag from '../../components/Tag';
import ZoomImage from '../../components/ZoomImage';

const Blog = ({ blog }) => {
  const { data, uid, tags } = blog;

  return data && (
    <article className="grid grid-cols-12">
      <div className="col-span-12 sm:hidden">
        <img src={data?.banner?.url} alt={data?.banner?.alt} />
      </div>
      <div className="mt-6 col-span-12 sm:col-span-6 lg:col-span-5 lg:justify-end sm:w-4/5">
        <div className="text-sm font-light text-d-gray-9">
          <time dateTime={data?.date}>{formatDate(data?.date)}</time>
        </div>
        <div>
          <h3 className="text-4xl font-extralight leading-8 normal-case">
            <Link to={`/en-us/blog/${uid}`} className="text-d-gray-10">
              {data?.title[0].text}
            </Link>
          </h3>
          <div className="flex flex-wrap">
            {tags.map(tag => (
              <Tag key={tag} text={tag} />
            ))}
          </div>
        </div>
        <div className="text-18 text-d-gray-9 max-w-non leading-7 font-light">
          {data?.description}
        </div>
      </div>
      <ZoomImage src={data.banner.url} alt={data.banner.alt} className="col-span-6 lg:col-span-6 hidden sm:block lg:w-5/6" />
    </article>
  );
};

const BlogList = ({ blogs }) => {
  return (
    <ul>
      {blogs?.map(blog => (
        <li key={blog.uid} className="mt-12">
          <Blog blog={blog} />
        </li>
      ))}
    </ul>
  );
};

export default BlogList;
